import React, { useEffect, useState } from "react";

const Table = ({ dataset, formatPrice }) => {
  const [dataToRender, setDataToRender] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Update the table data whenever the dataset changes
  useEffect(() => {
    setDataToRender([...dataset]); // Copy dataset to avoid mutation
    console.log(dataset);
  }, [dataset]);

  // Handle Sorting
  const sortData = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    const sortedData = [...dataToRender].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setDataToRender(sortedData);
    setSortConfig({ key, direction });
  };

  // Render sort arrows
  const renderSortArrows = (key) => {
    const isActiveKey = sortConfig.key === key;
    const ascClass = isActiveKey && sortConfig.direction === "asc" ? "text-black" : "text-gray-400";
    const descClass = isActiveKey && sortConfig.direction === "desc" ? "text-black" : "text-gray-400";

    return (
      <span className="ml-2">
        <span className={`inline-block ${ascClass}`}>▲</span>
        <span className={`inline-block ${descClass}`}>▼</span>
      </span>
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-fixed border-collapse border border-gray-300 shadow-md text-sm">
        <thead className="bg-gray-200">
          <tr>
            <th
              className="w-1/4 p-3 border border-gray-300 font-semibold text-left cursor-pointer"
              onClick={() => sortData("Location")}
            >
              Location {renderSortArrows("Location")}
            </th>
            <th
              className="w-1/4 p-3 border border-gray-300 font-semibold text-left cursor-pointer"
              onClick={() => sortData("Date")}
            >
              Date {renderSortArrows("Date")}
            </th>
            <th
              className="w-1/4 p-3 border border-gray-300 font-semibold text-left cursor-pointer"
              onClick={() => sortData("Website")}
            >
              Website {renderSortArrows("Website")}
            </th>
            <th
              className="w-1/4 p-3 border border-gray-300 font-semibold text-left cursor-pointer"
              onClick={() => sortData("Price")}
            >
              Price {renderSortArrows("Price")}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {dataToRender.map((entry, index) => (
            <tr
              key={`${entry.Location}-${entry.Date}-${entry.Website}-${index}`}
              className="hover:bg-gray-100"
            >
              <td className="p-3 border border-gray-300 truncate">{entry.Location}</td>
              <td className="p-3 border border-gray-300 whitespace-nowrap">
                {entry.Date.slice(0, 10)}
              </td>
              <td className="p-3 border border-gray-300 truncate">{entry.Website}</td>
              <td className="p-3 border border-gray-300 font-bold">
                {entry.Price !== undefined && entry.Price !== null
                  ? formatPrice(entry.Price) + " €"
                  : "No data"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
