import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUser, setLastLogin, setUsername } from '../store/userSlice';
import PricesDisplayElement from './pricesDisplayElement';

const PricesDashboard = (props) => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const lastLogin = useSelector((state) => state.user.lastLogin);
  const navigate = useNavigate();

  const [currentKey, setCurrentKey] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  // Lowest, highest, and my price hooks
  const [lowestPrice, setLowestPrice] = useState(null);
  const [lowestPriceOrigin, setLowestPriceOrigin] = useState(null);
  const [lowestPriceURL, setLowestPriceURL] = useState(null);
  const [highestPrice, setHighestPrice] = useState(null);
  const [highestPriceOrigin, setHighestPriceOrigin] = useState(null);
  const [highestPriceURL, setHighestPriceURL] = useState(null);
  const [myPrice, setMyPrice] = useState(null);
  const [myPriceOrigin, setMyPriceOrigin] = useState(null);
  const [myPriceURL, setMyPriceURL] = useState(null);

  const data = props.data;
  const props_currentLocation = props.location;
  const props_currentKey = props.currentKey;

  useEffect(() => {
    setCurrentKey(props_currentKey);
    setCurrentLocation(props_currentLocation);

    // Set lowest price
    setLowestPrice(data[props_currentLocation]?.["lowestlowestPrice"] ?? null);
    setLowestPriceOrigin(data[props_currentLocation]?.["lowestlowestpriceOrigin"] ?? null);
    setLowestPriceURL(data[props_currentLocation]?.["lowestlowestpriceUrl"] ?? null);

    // Set highest price
    setHighestPrice(data[props_currentLocation]?.["highestlowestPrice"] ?? null);
    setHighestPriceOrigin(data[props_currentLocation]?.["highestlowestpriceOrigin"] ?? null);
    setHighestPriceURL(data[props_currentLocation]?.["highestlowestpriceUrl"] ?? null);

    // Set my price
    setMyPrice(data[props_currentLocation]?.["myPrice"] ?? null);
    setMyPriceOrigin(data[props_currentLocation]?.["myPriceOrigin"] ?? null);
    setMyPriceURL(data[props_currentLocation]?.["myPriceUrl"] ?? "No Data");
  }, [data, props_currentLocation, props_currentKey]);

  const RedirectDetails = () => {
    const data = {
      endpoint: props_currentKey,
      current_location: props_currentLocation
    };
    navigate("/details", { state: data });
  };

  const TranslateNames = () => {
    switch (currentKey) {
      case "checkin_today":
        return "Check-In Today";
      case "checkin_3weeks":
        return "Check-In 3 Weeks";
      case "checkin_3months":
        return "Check-In 3 Months";
      default:
        return currentKey;
    }
  };

  const DoesContainNumber = (str) => {
    const regex = /\d/;
    return regex.test(str);
  };

  return (
    <div className="grid grid-cols-1 gap-4 bg-white p-6 md:grid-cols-2 lg:grid-cols-4">
      {currentKey && (
        <h2 className="col-span-full text-2xl font-bold text-gray-900 dark:text-white border-l-4 border-teal-400 pl-4">
          {TranslateNames()}
        </h2>
      )}

      {/* Lowest Price Section */}
      <div className="bg-green-100 p-4 shadow-md rounded-md flex flex-col justify-between">
        <h5 className="text-lg font-semibold text-gray-900 dark:text-white">Lowest Price:</h5>
        {lowestPrice && lowestPriceOrigin && lowestPriceURL && (
          <PricesDisplayElement
            displayText="Lowest Price"
            price={lowestPrice}
            origin={lowestPriceOrigin}
            url={lowestPriceURL}
          />
        )}
      </div>

      {/* Highest Price Section */}
      <div className="bg-red-100 p-4 shadow-md rounded-md flex flex-col justify-between">
        <h5 className="text-lg font-semibold text-gray-900 dark:text-white">Highest Price:</h5>
        {highestPrice && highestPriceOrigin && highestPriceURL && (
          <PricesDisplayElement
            displayText="Highest Price"
            price={highestPrice}
            origin={highestPriceOrigin}
            url={highestPriceURL}
          />
        )}
      </div>

      {/* My Price Section */}
      <div className="bg-gray-200 p-4 shadow-md rounded-md flex flex-col justify-between">
        <h5 className="text-lg font-semibold text-gray-900 dark:text-white">Your Price:</h5>
        {myPrice && myPriceOrigin && (
          <PricesDisplayElement
            displayText="My Price"
            price={myPrice}
            origin={myPriceOrigin}
            url={myPriceURL}
          />
        )}
      </div>

      {/* Details Button Section */}
      <div className="flex flex-col justify-center items-center">
        {currentKey && currentLocation && (
          <button
            type="button"
            className="text-white bg-blue-900 hover:bg-blue-800 px-5 py-2.5 text-center rounded-md inline-flex items-center focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={RedirectDetails}
          >
            View Details
            <svg
              className="ml-2 w-3.5 h-3.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        )}

        {/* Price Comparison Button */}
        <button
          className={`mt-4 text-white font-bold py-4 px-4 rounded-full ${
            !lowestPrice ||
            !myPrice ||
            !DoesContainNumber(String(myPrice)) ||
            !DoesContainNumber(String(lowestPrice))
              ? 'bg-gray-500'
              : parseFloat(String(lowestPrice).replace(/[^0-9.-]+/g, "")) >= parseFloat(String(myPrice).replace(/[^0-9.-]+/g, ""))
              ? 'bg-green-500 hover:bg-green-700'
              : 'bg-red-500 hover:bg-red-700'
          }`}
        />
      </div>
    </div>
  );
};

export default PricesDashboard;
