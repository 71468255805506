import React, { useState, useEffect } from 'react';
import useWindowSize from '../utils/getWindowSize';

const ComputedTable = ({ dataset, formatPrice }) => {
  const [dataToRender, setDataToRender] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const { width } = useWindowSize(); // Get the screen width
  const isMobile = width <= 768; // Define the breakpoint for mobile view

  // Group the dataset by Location and Date (ignoring the time)
  const groupByLocationAndDate = () => {
    const groupedData = {};

    dataset.forEach((entry) => {
      const { Location, Date, Website, Price } = entry;
      const dateOnly = Date.slice(0, 10); // Get only the date part (YYYY-MM-DD)
      const key = `${Location}-${dateOnly}`; // Group key based on Location and Date only

      if (!groupedData[key]) {
        groupedData[key] = {
          Location,
          Date: dateOnly,
          websites: {}
        };
      }

      groupedData[key].websites[Website] = Price;
    });

    return Object.values(groupedData);
  };

  useEffect(() => {
    const groupedData = groupByLocationAndDate();
    setDataToRender(groupedData);
  }, [dataset]);

  const sortWebsites = (websites) => {
    return websites.sort((a, b) => {
      if (a.toLowerCase().includes("homepage") && !b.toLowerCase().includes("homepage")) {
        return -1;
      }
      return a.localeCompare(b);
    });
  };

  const sortData = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    const sortedData = [...dataToRender].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setDataToRender(sortedData);
    setSortConfig({ key, direction });
  };

  const renderSortArrows = (key) => {
    const isActiveKey = sortConfig.key === key;
    const ascClass = isActiveKey && sortConfig.direction === "asc" ? "text-black" : "text-gray-400";
    const descClass = isActiveKey && sortConfig.direction === "desc" ? "text-black" : "text-gray-400";

    return (
      <span className="ml-2">
        <span className={`inline-block ${ascClass}`}>▲</span>
        <span className={`inline-block ${descClass}`}>▼</span>
      </span>
    );
  };

  const getWebsites = () => {
    const websites = new Set();
    dataset.forEach((entry) => {
      websites.add(entry.Website);
    });
    return sortWebsites(Array.from(websites)); // Sort websites before returning
  };

  const websites = getWebsites();

  // Thicker border and darker gray for homepage column
  const homepageColumnStyle = "border-2 border-gray-600"; 

  // Mobile View - Scrollable Table
  const renderMobileView = () => {
    return (
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300 text-sm">
          <thead className="bg-gray-200 sticky top-0 z-10">
            <tr>
              <th className="p-3 border border-gray-300 font-semibold text-left">Location</th>
              <th className="p-3 border border-gray-300 font-semibold text-left">Date</th>
              {websites.map((website, index) => (
                <th
                  key={index}
                  className={`p-3 border border-gray-300 font-semibold text-left ${website.toLowerCase().includes("homepage") ? homepageColumnStyle : ""}`}
                >
                  {website.toLowerCase().includes("homepage") ? "Your Homepage" : website}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToRender.map((entry, index) => {
              // Find the website with the lowest price in each row
              const lowestPriceWebsite = Object.keys(entry.websites).reduce((lowest, website) => {
                if (!lowest || entry.websites[website] < entry.websites[lowest]) {
                  return website;
                }
                return lowest;
              }, null);

              return (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-3 border border-gray-300">{entry.Location}</td>
                  <td className="p-3 border border-gray-300">{entry.Date}</td>
                  {websites.map((website, websiteIndex) => (
                    <td
                      key={websiteIndex}
                      className={`p-3 border border-gray-300 ${website === lowestPriceWebsite ? "bg-green-200" : ""} ${
                        website.toLowerCase().includes("homepage") ? homepageColumnStyle : ""
                      }`}
                    >
                      {entry.websites[website] !== "No data" && entry.websites[website]
                        ? formatPrice(entry.websites[website]) + " €"
                        : "No data"}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  // Desktop View - Full Table with Evenly Distributed Columns
  const renderDesktopView = () => {
    return (
      <div className="overflow-x-auto">
        <table className="table-fixed w-full border-collapse border border-gray-300 text-sm">
          <thead className="bg-gray-200 sticky top-0 z-10">
            <tr>
              <th className="p-3 border border-gray-300 font-semibold text-left w-1/5">Location</th>
              <th className="p-3 border border-gray-300 font-semibold text-left w-1/5">Date</th>
              {websites.map((website, index) => (
                <th
                  key={index}
                  className={`p-3 border border-gray-300 font-semibold text-left w-1/5 ${website.toLowerCase().includes("homepage") ? homepageColumnStyle : ""}`}
                >
                  {website.toLowerCase().includes("homepage") ? "Your Homepage" : website}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToRender.map((entry, index) => {
              // Find the website with the lowest price in each row
              const lowestPriceWebsite = Object.keys(entry.websites).reduce((lowest, website) => {
                if (!lowest || entry.websites[website] < entry.websites[lowest]) {
                  return website;
                }
                return lowest;
              }, null);

              return (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-3 border border-gray-300 truncate">{entry.Location}</td>
                  <td className="p-3 border border-gray-300 truncate">{entry.Date}</td>
                  {websites.map((website, websiteIndex) => (
                    <td
                      key={websiteIndex}
                      className={`p-3 border border-gray-300 ${website === lowestPriceWebsite ? "bg-green-200" : ""} ${
                        website.toLowerCase().includes("homepage") ? homepageColumnStyle : ""
                      }`}
                    >
                      {entry.websites[website] !== "No data" && entry.websites[website]
                        ? formatPrice(entry.websites[website]) + " €"
                        : "No data"}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return isMobile ? renderMobileView() : renderDesktopView();
};

export default ComputedTable;
