import React, { useEffect, useState } from "react";
import Table from "./displayDataTable";
import Graph from "./displayDataGraph";
import ComputedTable from "./displayDataComputedTable";

const DisplayAllData = ({ data }) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const [dateKeys, setDateKeys] = useState([]);
  const [websiteKeys, setWebsiteKeys] = useState([]);

  const [chosenLocation, setChosenLocation] = useState("all");
  const [chosenDate, setChosenDate] = useState("all");
  const [chosenWebsite, setChosenWebsite] = useState("all");

  const [filteredData, setFilteredData] = useState([]);
  const [onlyValidData, setOnlyValidData] = useState([]);
  const [toggleOnlyValidData, setToggleOnlyValidData] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Computed Table");

  

  const formatPrice = (price) =>
    new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);

  const toggleValidData = () => {
    setToggleOnlyValidData((prev) => !prev);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const filterData = () => {
    let tempFilteredData = data;

    tempFilteredData = tempFilteredData.filter((entry) => {
      const locationMatch =
        chosenLocation === "all" || entry.Location === chosenLocation;
      const dateMatch =
        chosenDate === "all" || entry.Date.startsWith(chosenDate);
      const websiteMatch =
        chosenWebsite === "all" || entry.Website === chosenWebsite;

      return locationMatch && dateMatch && websiteMatch;
    });

    const validPriceData = tempFilteredData.filter(
      (entry) => typeof entry.Price === "number" && entry.Price > 0
    );

    setOnlyValidData(validPriceData);
    setFilteredData(tempFilteredData);
  };

  const extractKeys = (data) => {
    const tempLocations = [...new Set(data.map((entry) => entry.Location))];
    const tempDates = [...new Set(data.map((entry) => entry.Date))];
    const tempWebsites = [...new Set(data.map((entry) => entry.Website))];

    setLocationKeys(tempLocations);
    setDateKeys(tempDates);
    setWebsiteKeys(tempWebsites);
  };

  useEffect(() => {
    extractKeys(data);
    filterData();
  }, [data]);

  useEffect(() => {
    filterData();
  }, [chosenLocation, chosenDate, chosenWebsite]);

  return (
    <>
      {/* Filters */}
      <div className="flex flex-wrap items-center gap-4 p-4 bg-gray-100 rounded-md shadow-md">
  {/* Location Filter */}
  <div className="flex flex-col">
    <label className="mb-1 text-sm font-medium text-gray-700">Filter by Location</label>
    <select
      value={chosenLocation}
      onChange={(e) => setChosenLocation(e.target.value)}
      className="p-2 border border-gray-300 rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
    >
      <option value="all">All Locations</option>
      {locationKeys.map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  </div>

  {/* Date Filter */}
  <div className="flex flex-col">
    <label className="mb-1 text-sm font-medium text-gray-700">Filter by Date</label>
    <select
      value={chosenDate}
      onChange={(e) => setChosenDate(e.target.value)}
      className="p-2 border border-gray-300 rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
    >
      <option value="all">All Dates</option>
      {dateKeys.map((key) => (
        <option key={key} value={key.slice(0, 10)}>
          {key.slice(0, 10)}
        </option>
      ))}
    </select>
  </div>

  {/* Website Filter */}
  <div className="flex flex-col">
    <label className="mb-1 text-sm font-medium text-gray-700">Filter by Website</label>
    <select
      value={chosenWebsite}
      onChange={(e) => setChosenWebsite(e.target.value)}
      className="p-2 border border-gray-300 rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
    >
      <option value="all">All Websites</option>
      {websiteKeys.map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  </div>

  

  {/* Toggle View Button */}
  <div className="flex flex-col">
  <label className="mb-1 text-sm font-medium text-gray-700">View:</label>
  <select
        value={selectedOption}
        onChange={handleOptionChange}
        className="p-2 border border-gray-300 rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
      >        
        <option value="Computed Table">Comfort</option>     
        <option value="Graph">Graph</option>        
        <option value="Simple Table">Simple</option>   
      </select>
  </div>
  {/* Toggle Valid Data */}
  {selectedOption == "Computed Table" ? <></> : 
  
  <div className="flex flex-col">
    <label className="text-sm font-medium text-gray-700">Show only valid data</label>
    <input
      type="checkbox"
      checked={toggleOnlyValidData}
      onChange={toggleValidData}
      className="h-4 w-4 border-gray-300 rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
    
  </div>
  }
</div>



      {/* Graph */}
      {selectedOption == "Simple Table" ? 
        <Table dataset={toggleOnlyValidData ? onlyValidData : filteredData} formatPrice={formatPrice} />        
        :
        selectedOption == "Graph" ? 
          <Graph dataset={toggleOnlyValidData ? onlyValidData : filteredData} formatPrice={formatPrice}/>
          :
          <ComputedTable dataset={filteredData} formatPrice={formatPrice}/>
        
        
      }
      

      {/* Table */}
      
    </>
  );
};

export default DisplayAllData;
